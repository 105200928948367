.RoomDetails {
	&-Hero {
		position: relative;

		@include viewport(medium) {
			padding-bottom: 0;
		}

		.BackgroundImage::after {
			display: none;
		}
	}

	&-HeroLinks {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;

		@include viewport(medium) {
			flex-direction: row;
			margin-top: 0;
			padding: 30px;
		}

		@include viewport(large) {
			.RoomDetails-HeroVideo & {
				flex-direction: row;
				position: absolute;
				bottom: 30px;
				left: 30px;
				z-index: 2;
				margin-top: 0;
				padding: 0;

				@include rtl {
					left: auto;
					right: 30px;
				}
			}
		}
	}

	&-CTARow {
		.CTA {
			display: block;
		}

		&:not(:last-child) {
			margin-bottom: 27px;

			@include viewport(medium) {
				margin-bottom: 0;
				margin-right: 30px;

				@include rtl {
					margin-right: 0;
					margin-left: 30px;
				}
			}
		}
	}
}
