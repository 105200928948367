.LanaiAirForm {
	&-Section {
		margin-bottom: 50px;
	}

	&-Header {
		margin-top: 0;
		margin-bottom: 35px;
	}

	&-SubHeader {
		margin-top: 15px;
		margin-bottom: 27px;
	}

	&-SubText {
		margin-top: 0;
		margin-bottom: 27px;
	}

	&-RadioButtons {
		margin-top: 15px;
		.RadioButtons-label {
			font-size: rem(20, 16);
		}
		.RadioButtons-button {
			margin: 0;
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}

	&-GuestOrTravelPlanner {
		margin-top: -5px;
		@include viewport(medium) {
			margin-top: 15px;
		}
		.RadioButtons-button {
			&:not(:last-child) {
				margin-bottom: 10px;
				@include viewport(medium) {
					margin-bottom: 0;
				}
			}
		}
	}

	&-PassengerInfo {
		.LanaiAirForm-SubHeader {
			margin-bottom: 10px;
		}
		.LanaiAirForm-SubText {
			margin-bottom: 20px;
		}
	}

	&-GroundTransportation {
		.LanaiAirForm {
			&-SubText:not(.LanaiAirForm-grayLine--bottom) {
				margin-bottom: 15px;
			}
			&--oahuDisclaimer {
				& .formElement-label {
					position: relative;
					opacity: 1;
				}
				& a {
					color: inherit;
				}
			}
		}
	}

	&-grayLine {
		border-top: 1px solid $gray3;
		padding-top: 27px;
		margin-top: 0px;

		&--bottom {
			border-bottom: 1px solid $gray3;
			padding-bottom: 30px;
			margin-bottom: 30px;
		}
	}

	.ContactForm {
		padding-top: 75px;

		.CTA-light-on-light {
			border-color: $black;
		}

		.ContactForm-SubmitCTA:disabled {
			background: $gray2;
			cursor: default;
			border: 1px solid $gray2;
		}

		.ContactForm-SubmitCTA:disabled:hover {
			color: $white;
		}
	}

	.hidden {
		display: none;
	}

	.formElement-field.ty-b2 {
		line-height: 1.5em;
	}
}
