.PPCHero {
	$separator-height: 2px;
	&-container {
		display: flex;
		flex-direction: column;
		@media only screen and (min-width: '1100px') {
			//based on tablet mode viewport with header/footer not large breakpoint
			flex-direction: row;
		}
		.PPCHero-headingContainer {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			flex: 0 0 35%;
			padding: 20px 45px;
			padding-top: 20px;
			.Heading {
				text-align: center;
				width: 100%;
				line-height: em(16, 18);

				@include viewport(medium) {
					line-height: em(27, 18);
				}

				&-eyebrow {
					@include typography(h4);
				}

				&-title {
					@include typography(h1);
					margin: 10px 0 (25px - $separator-height);
					&::after {
						background-color: #bababa;
						content: '';
						display: block;
						height: 1px;
						margin: 0 auto;
						position: relative;
						top: (10px - $separator-height);
						width: 100%;
					}

					@include viewport(xlarge) {
						font-size: 2.75rem;
						margin: 10px 0 (32px - $separator-height);
					}
					&:lang(ar) {
						letter-spacing: -1px;
					}
				}

				&-subtitle {
					@include typography(c2);
				}
			}
			.PPCHero-linksContainer {
				text-align: center;
				padding-top: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				gap: 15px;
				.CTA {
					@include typography(c3);
				}
			}
			.ppcCAW {
				display: none;
				min-height: 420px;
				@media only screen and (min-width: '1100px') {
					//based on tablet mode viewport with header/footer not large breakpoint
					display: block;
				}
				.caw-popup__wrapper {
					padding: 20px 0px;
					.Calendar-row {
						position: absolute;
					}
				}
			}
		}
		.PPCHero-galleryContainer {
			color: white;
			background-color: black;
			flex: 0 0 65%;
			overflow: hidden;
			position: relative;
			.PPCCarousel-list {
				overflow: hidden;
				.CarouselSlide {
					.Image-img {
						object-fit: contain;
					}
				}
			}
			.PPCCarousel-desc p {
				padding-bottom: 40px;
			}
		}
	}
}
