.ValueDates {
	&-heading {
		@include component-spacing($small: 40px, $medium: 60px, $large: 80px, $xlarge: 120px);

		margin-top: 0;
		text-align: center;
		&::after {
			display: none;
		}
	}
	&-lists {
		@include component-spacing($small: 40px, $medium: 60px, $large: 80px, $xlarge: 120px);
	}
	&-lists .BulletedList {
		&:last-child .BulletedList-body {
			border-bottom: none;
		}
		.BulletedList-body {
			padding-top: 40px;
			@include viewport(medium) {
				padding-top: 60px;
			}
			@include viewport(xlarge) {
				padding-left: percentage(1/12);
				padding-right: percentage(1/12);
			}
		}
		.BulletedList-heading {
			@include viewport(medium) {
				max-width: 50%;
			}
		}
		.BulletedList-list {
			margin-top: 20px;
			@include viewport(medium) {
				columns: auto 2;
				column-gap: $gutter_large;
			}
		}
	}
}
