.PackageDetailHeader {
	&-container {
		text-align: center;

		@include viewport(large) {
			display: flex;
			justify-content: space-between;
		}
	}

	&-heading {
		margin-bottom: 40px;

		@include viewport(large) {
			text-align: left;
			margin-bottom: 0;

			@include rtl {
				text-align: right;
			}
		}
	}

	&-headingEyebrow {
		display: inline-block;
		margin-bottom: 10px;
	}

	&-headingTitle {
		margin: 0;
	}

	&-contact {
		display: flex;
		max-width: 300px;
		margin: 0 auto;
		justify-content: space-around;

		@include viewport(large) {
			margin: 0;
			max-width: none;
		}
	}

	&-contactWrapper {
		display: flex;
		min-width: 30%;
		justify-content: center;

		&:last-child {
			justify-content: flex-start;
		}

		@include viewport(large) {
			min-width: 0;

			&:first-child {
				margin-right: 30px;

				@include rtl {
					margin-right: 0;
					margin-left: 30px;
				}
			}
		}
	}

	&-phone {
		&::before {
			@include icon('icon-contact_phone');
			font-size: 51px;

			// Tech debt: Add it to the icon-names.scss.
			// TODO: Remove it when tech debt is done.
			letter-spacing: 0;
			display: block;
			margin: 18px 0 28px;
		}
	}

	&-email {
		&::before {
			@include icon('icon-contact_email');
			font-size: 51px;

			// Tech debt: Add it to the icon-names.scss.
			// TODO: Remove it when tech debt is done.
			letter-spacing: 0;
			display: block;
			margin: 18px 0 28px;
		}
	}
}
