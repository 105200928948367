.MeetingsDetailHero {
	&-contact {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 25px;
		flex-wrap: wrap;

		.Heading {
			text-align: center;
			padding-bottom: 20px;
			width: 100%;

			@include viewport(large) {
				flex: 1;
				text-align: left;
				padding-bottom: 0;
			}

			.Heading-title {
				margin: 0;
			}
		}

		&-phone {
			margin: 0 auto;
			padding: 0;

			@include viewport(large) {
				padding: 0 20px;
				align-self: flex-end;
			}

			color: black;
			text-align: center;

			&::before {
				@include icon('icon-contact_phone');
				font-size: 51px;
				letter-spacing: 0;
				display: block;
				margin: 18px 0 28px;
			}
		}

		&-us-cta {
			color: black;
			margin: 0 auto;
			padding: 0;

			@include viewport(large) {
				padding: 0 10px;
				align-self: flex-end;
			}

			text-align: center;

			&::before {
				@include icon('icon-contact_email');
				font-size: 51px;
				letter-spacing: 0;
				display: block;
				margin: 18px 0 28px;
			}
		}
	}

	&-hero {
		.BackgroundImage {
			&::after {
				@include gradient2transparent($height: 45%);

				@include viewport(medium) {
					@include gradient2transparent($height: 55%);
				}

				@include viewport(large) {
					@include gradient2transparent($height: 45%);
				}
			}
		}
	}
}
