.MembershipForm {
	&-Section {
		margin-bottom: 50px;
	}

	&-Header {
		margin-top: 0;
		margin-bottom: 35px;
	}

	&-SubHeader {
		margin-top: 15px;
		margin-bottom: 27px;
	}

	&-SubText {
		margin-top: 0;
		margin-bottom: 27px;
	}

	&-RadioButtons {
		margin-top: 15px;
		.RadioButtons-label {
			font-size: rem(20, 16);
		}
		.RadioButtons-button {
			margin: 0;
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}

	&-TierDropdown {
		margin-bottom: 3px;
	}

	&-CardInfo {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include viewport(medium) {
			flex-direction: row;
		}

		&-Container {
			border: 1px solid $gray3;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 30px 60px;
			margin-bottom: 30px;
		}

		&-Image {
			width: 100%;
			height: 0;
			padding-bottom: 60%;
			color: $white;
			display: flex;
			flex-direction: column;
			justify-content: start;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;

			&Container {
				width: 178px;
				@include viewport(medium) {
					margin-right: 15px;
				}
			}

			&-HotelName {
				margin-top: 10px;
				margin-left: 10px;
				font-size: 18px;
				line-height: 26px;
				letter-spacing: 0.35px;
				text-transform: none;
				font-weight: 300;
			}

			&-TierName {
				margin-top: 5px;
				margin-left: 10px;
				font-size: 10px;
			}
		}

		&-Text {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			margin: 30px 0 0;

			@include viewport(medium) {
				margin: 0 0 0 15px;
				justify-content: center;
				align-items: start;
				padding: 10px 0;

				text-align: left;
				@include rtl {
					text-align: right;
				}
			}
		}

		&-TierName {
			margin-top: 0;
			margin-bottom: 15px;
		}

		&-TierFee {
			margin-top: 0;
			margin-bottom: 2px;
		}

		&-TermsConditions {
			color: $gray7;
			text-transform: none;
			letter-spacing: 1px;
		}
	}

	&-ThankYou {
		&-Checkmark {
			font-size: 28px;
		}

		&-Heading {
			font-weight: 300;
			font-size: 22px;
			letter-spacing: 0.85px;
			text-transform: none;
		}
	}

	&-grayLine {
		border-top: 1px solid $gray3;
		padding-top: 27px;
		margin-top: 0px;
	}

	.ContactForm {
		padding-top: 75px;

		.CTA-light-on-light {
			border-color: $black;
		}

		.ContactForm-SubmitCTA:disabled {
			background: $gray2;
			cursor: default;
			border: 1px solid $gray2;
		}

		.ContactForm-SubmitCTA:disabled:hover {
			color: $white;
		}
	}

	&.hidden,
	.hidden {
		display: none;
	}

	& .LegalAgreement a {
		color: $black;
	}

	& .LegalDisclaimer {
		& a {
			color: $black;
		}
	}
}
