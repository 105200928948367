.ActivityCalendar.container {
	// -----------------------------------------------
	// specific styles for 3.0 activity calendar
	// -----------------------------------------------
	padding-bottom: 20px;

	&--light {
		background: #fff;
		h1.title {
			background: #fff;
			color: #686868;
		}
		.subtitle {
			background: #fff;
			color: #686868;
		}
	}

	&--dark {
		background: #000;
		h1.title {
			background: #000;
			color: #fff;
		}
		.subtitle {
			background: #000;
			color: #fff;
		}
	}

	.fs-activity-calendar {
		.main {
			background: #f7f7f7;
			border: 1px solid #ececec;
		}
	}

	// -----------------------------------------------
	// imported 2.0 activity calendar styles from AEM:
	// etc/designs/fourseasons/components/property/prearrival-activity-calendar/clientlibs/fs-lanai-calendar-ajax/src/scss/_base.scss
	// -----------------------------------------------
	*,
	*:before,
	*:after {
		box-sizing: border-box;
		outline: none;
	}

	.fs-activity-calendar {
		font-family: $ac-primary-font;
		font-weight: $ac-primary-font-weight;
		color: $ac-primary-text-color;
		font-size: $ac-primary-font-size;
		line-height: 1.4;
		max-width: 700px;
		margin: 0 auto;
		padding-bottom: 40px;

		h1.title {
			font-size: 20px;
			font-weight: $ac-primary-font-weight;
			text-align: center;
			text-transform: uppercase;
			padding: 20px;
			margin: 0;
			border-bottom: 1px solid $ac-border-color;
		}

		.subtitle {
			display: none;
		}

		button {
			font-size: $ac-primary-font-size;
			font-family: $ac-primary-font;
			line-height: 1.4;
		}

		i.caret {
			width: 0;
			height: 0;

			&.caret-down {
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-top: 6px solid $ac-link-color;
			}

			&.caret-right {
				border-top: 6px solid transparent;
				border-bottom: 6px solid transparent;
				border-left: 6px solid $ac-link-color;
			}

			&.caret-up {
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-bottom: 6px solid $ac-link-color;
			}

			&.caret-left {
				border-top: 6px solid transparent;
				border-bottom: 6px solid transparent;
				border-right: 6px solid $ac-link-color;
			}
		}

		@media (min-width: $ac-screen-sm) {
			padding: 0 20px;

			h1.title {
				border: none;
			}

			.subtitle {
				display: block;
				text-align: center;
				padding: 0 20px;
			}
		}
	}

	// -----------------------------------------------
	// imported 2.0 activity calendar styles from AEM:
	// etc/designs/fourseasons/components/property/prearrival-activity-calendar/clientlibs/fs-lanai-calendar-ajax/src/scss/components/_calendar-header.scss
	// -----------------------------------------------
	.fs-activity-calendar-header .date-tabs {
		display: none;

		@media (min-width: $ac-screen-sm) {
			display: block;
			margin-bottom: 5px;
			overflow: hidden;

			button.date-tab {
				background: none;
				border: none;
				border-bottom: 5px solid transparent;
				color: $ac-heading-color;
				cursor: pointer;
				float: left;
				outline: none;
				padding: 0;
				width: (100% / 7);
				text-align: center;

				&:first-child.is-selected span {
					border-left: 1px solid transparent;
				}

				&:last-child.is-selected span {
					border-right: 1px solid transparent;
				}
			}

			.date-tab span.day {
				padding-top: 5px;
			}

			.date-tab span.date {
				padding-bottom: 5px;
			}

			.date-tab.is-selected {
				background: #fff;
				border-bottom: 5px solid $ac-teal;

				span {
					border-left: 1px solid $ac-border-color;
					border-right: 1px solid $ac-border-color;
				}
			}

			.date-tab span {
				display: block;
			}
		}
	}

	.fs-activity-calendar-header .controls {
		background: #fff;
		border-bottom: 1px solid $ac-border-color;
		position: relative;
		display: flex;

		.date-heading {
			flex: 1;

			> div {
				position: relative;

				.date-selector {
					display: flex;
					align-items: center;
					justify-self: center;
					margin: 0 auto;
					justify-content: space-around;
					background: #f7f7f7;
					border: none;
					font-family: $ac-primary-font;
					cursor: pointer;
					font-size: 16px;
					color: $ac-heading-color;
					text-align: center;
					width: 100%;
					max-width: 200px;
					padding: 5px 10px;
				}
			}

			.react-datepicker-popper {
				width: 100%;
				margin-top: -5px;
			}

			.react-datepicker__input-container {
				padding: 10px 0;
				position: relative;
				text-align: center;
			}

			.react-datepicker__header {
				background: #777;
				color: #fff;
				text-align: center;
				border-top-left-radius: 2px;
				border-top-right-radius: 2px;
				padding-top: 8px;
				position: relative;
			}

			.react-datepicker__navigation {
				background: none;
				border: none;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				padding: 0;
				margin: 0;
				height: 38px;
				width: 50px;
				z-index: 1;

				&:before {
					content: '';
				}
			}

			.react-datepicker__navigation--previous {
				&:before {
					border-top: 6px solid transparent;
					border-bottom: 6px solid transparent;
					border-right: 6px solid #fff;
				}
			}

			.react-datepicker__navigation--next {
				right: 0;

				&:before {
					border-top: 6px solid transparent;
					border-bottom: 6px solid transparent;
					border-left: 6px solid #fff;
				}
			}

			.react-datepicker__current-month {
				padding: 0 0 10px;
			}

			.react-datepicker__month {
				text-align: center;
			}

			.react-datepicker__day-names,
			.react-datepicker__week {
				white-space: nowrap;
				display: flex;

				.react-datepicker__day:last-child {
					border-right: none;
				}
			}

			.react-datepicker__week:last-child {
				.react-datepicker__day {
					border-bottom: none;
				}
			}

			.react-datepicker__day {
				color: $ac-heading-color;
				cursor: pointer;
				padding: 0.4em;
				border-right: 1px solid #ccc;
				border-bottom: 1px solid #ccc;
			}

			.react-datepicker__day--selected {
				background: $ac-link-color;
				color: #fff;
			}

			.react-datepicker__day--outside-month {
				color: #fff;
			}

			.react-datepicker__day--disabled:not(.react-datepicker__day--outside-month) {
				color: lighten($ac-heading-color, 30%);
			}

			.react-datepicker__day-name,
			.react-datepicker__day,
			.react-datepicker__time-name {
				flex: 1 0 0%;
				line-height: 1.7rem;
				text-align: center;
			}

			.react-datepicker__triangle {
				display: none;
			}
		}

		.date-picker {
			background: #fff;
			margin: 0 auto;
			max-width: 300px;
			position: relative;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
		}

		button.control-btn {
			background: none;
			align-items: center;
			justify-content: center;
			border: none;
			color: $ac-link-color;
			cursor: pointer;
			display: flex;
			outline: none;
			font-size: $ac-primary-font-size;
			font-weight: $ac-primary-font-weight;
			padding: 0;
			min-width: 40px;

			&:disabled {
				cursor: default;

				.caret {
					border: none;
				}
			}

			&.is-desktop {
				display: none;
			}
		}

		@media (min-width: $ac-screen-sm) {
			button.control-btn {
				&.is-desktop {
					padding: 0 15px;
					display: block;
				}

				&.is-mobile {
					display: none;
				}
			}
		}
	}

	// -----------------------------------------------
	// imported 2.0 activity calendar styles from AEM:
	// etc/designs/fourseasons/components/property/prearrival-activity-calendar/clientlibs/fs-lanai-calendar-ajax/src/scss/components/_activity-list.scss
	// -----------------------------------------------
	.fs-activity-calendar-list {
		list-style: none;
		margin: 0;
		padding: 5px;
	}

	.fs-activity-calendar-list-no-items {
		color: $ac-heading-color;
		font-size: 20px;
		text-align: center;
		padding: 200px 20px;

		@media (min-width: $ac-screen-sm) {
			padding: 200px 0;
		}
	}

	.fs-activity-calendar-list .activity {
		background: #fff;
		border: 1px solid $ac-border-color;
		display: flex;
		flex-direction: column;
		margin-bottom: 5px;

		.activity-header {
			background-color: #fff;
			border: none;
			cursor: pointer;
			align-items: center;
			display: flex;
			flex: 0 1 auto;
			text-align: left;
			padding: 0;
			min-height: 60px;
		}

		.activity-time {
			flex: 0 0 100px;
			text-align: center;
		}

		.activity-content {
			flex: 1 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 15px 0;

			.activity-title {
				font-size: 16px;
				font-weight: 200;
				color: $ac-heading-color;
				margin: 0;
				text-transform: uppercase;
			}

			a {
				color: $ac-link-color;
				text-decoration: none;
			}

			a:hover {
				text-decoration: underline;
			}
		}

		.activity-body {
			padding: 0 15px 15px;

			p {
				margin: 0 0 10px;
			}
		}

		.activity-actions {
			display: flex;
			align-items: center;
			justify-content: center;
			background: none;
			border: none;
			outline: none;
			cursor: pointer;
			padding: 0;
			width: 40px;
			min-width: 40px;
			height: 40px;

			span {
				display: none;
			}
		}

		@media (min-width: $ac-screen-sm) {
			.activity-actions {
				color: $ac-link-color;
				width: auto;
				padding: 0 15px;

				span {
					display: block;
					margin-right: 10px;
				}
			}
		}
	}
}
