.WeddingPackages {
	&-heading {
		border-top: 1px solid $black;
		text-align: center;
		padding: 25px 0 40px;

		@include viewport(medium) {
			padding: 45px 0 70px;
		}

		.Heading {
			&-title {
				margin: 12px 0 23px 0;

				@include viewport(medium) {
					margin-top: 24px;
				}
			}

			&-text {
				margin: 0;

				&:lang(ja) {
					font-size: 1rem;
				}
			}
		}
	}
}

.WeddingPackage {
	background-color: $gray4;
	padding: 40px 15px 20px;
	margin-bottom: 40px;

	@include viewport(medium) {
		padding: 40px 30px 25px;
		position: relative;

		&-intro {
			width: 70%;
		}
	}

	@include viewport(large) {
		padding: 50px 30px 30px;

		&-introWrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	&-title {
		margin: 0 0 20px;
	}

	&-description {
		margin: 0 0 25px;

		@include viewport(medium) {
			margin-bottom: 40px;
		}
	}

	&-sections {
		@include viewport(medium) {
			display: flex;
			border-top: 1px solid $separatorLight;
			padding: 25px 0 0;
			justify-content: space-between;
		}

		@include viewport(large) {
			padding-top: 17px;
		}

		@include viewport(xlarge) {
			padding-right: 15px;

			@include rtl {
				padding-right: 0;
				padding-left: 0;
			}
		}
	}

	&-section {
		border-top: 1px solid $separatorLight;

		&-heading {
			margin: 20px 0 0;
		}

		&-text {
			margin: 8px 0 20px;
		}

		@include viewport(medium) {
			border: none;
			text-align: left;
			margin: 0 auto;

			@include rtl {
				text-align: right;
			}

			&Wrapper {
				text-align: center;
				display: flex;
				width: 100%;

				&:first-child {
					.WeddingPackage-section {
						margin-left: 0;

						@include rtl {
							margin-left: auto;
							margin-right: 0;
						}
					}
				}

				&-2 {
					width: 29%;

					&:first-child {
						width: 69%;
						padding: 0 30px 0 0;

						@include rtl {
							padding: 0 0 0 30px;
						}
					}
				}

				&-3 {
					max-width: 30%;
				}
			}
		}

		@include viewport(large) {
			&Wrapper-3:last-child {
				text-align: left;
			}
		}
	}

	&-verticalDivision {
		display: none;
		width: 1px;
		margin: 0;
		background-color: $separatorLight;
		margin: 0 15px;
		align-self: stretch;

		@include viewport(medium) {
			&:not(:first-child):not(:last-child) {
				display: block;
			}
		}
	}

	.CTA {
		width: 100%;
		background-color: $gray4;
		margin: 15px 0 35px;

		@include viewport(medium) {
			position: absolute;
			width: auto;
			top: 41px;
			right: 32px;
			margin: 0;

			@include rtl {
				right: auto;
				left: 32px;
			}
		}

		@include viewport(large) {
			position: relative;
			top: -15px;
			right: 30px;
			min-width: 175px;

			@include rtl {
				right: auto;
				left: 30px;
			}
		}

		&:focus,
		&:hover {
			background-color: #000;
		}
	}
}
