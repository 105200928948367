.OfferDescriptionAndBooking {
	&-intro {
		@include component-spacing($small: 30px, $medium: 30px, $large: 30px, $xlarge: 40px);

		.Introduction-text {
			margin-bottom: 0;
		}
	}
	&-reservation {
		text-align: center;
		&-text {
			margin: 0;
		}
	}
	&-table {
		margin-top: 40px;

		@include component-spacing;

		@include viewport(medium) {
			margin-top: 60px;
		}

		@include viewport(large) {
			margin-top: 70px;
		}

		@include viewport(xlarge) {
			margin-top: 90px;
		}
		.TableList-item .TableList-item-title {
			max-width: 210px;
		}
	}
}
