.ExperiencesLanding,
.ExperiencesDetail {
	.StandardHero {
		&--dark {
			.Heading-title {
				&::after {
					background-color: white;
				}
			}
		}
		&--light {
			.Heading-title {
				&::after {
					background-color: black;
				}
			}
		}
		.Heading-title {
			position: relative;
			&::after {
				position: absolute;
				left: calc(50% - 20px);
				bottom: -13px;
				height: 2px;
				width: 40px;
				content: '';
			}
		}
	}
}
