.FeaturedProduct {
	.Heading-text {
		text-align: center;
	}

	margin-bottom: 40px;

	@include viewport(medium) {
		margin-bottom: 0px;
	}
}
