.ProductsConfirmation {
	&.TabbedContainers {
		.BodySection {
			padding-bottom: 0px;
		}
	}

	.Text-Box {
		&-text {
			@include typography(cc2);
			font-size: 22px;
			line-height: 30px;
			font-weight: 300;
		}

		.CTA {
			font-size: 22px;
			line-height: 30px;
			font-weight: 300;
			text-transform: none;
			padding-bottom: 0px;
			letter-spacing: normal;
		}
	}

	.TabContainer {
		background: linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%);
		padding-bottom: 40px;

		@include viewport(large) {
			padding-bottom: 80px;
		}

		.Heading {
			&-title {
				margin-top: 15px;
				margin-bottom: 0px;
			}
		}

		.CardCarousel-item {
			background-color: transparent;
			.ProductCard-react-ssr-placeholder {
				background-color: transparent;
				.TallImageCard {
					background-color: transparent;
				}
			}
		}
	}
}
