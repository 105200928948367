.Hero.MeetingsAndEventsHero {
	.BackgroundImage {
		.MeetingsAndEventsHero-noSubtitle {
			.Heading-title {
				&::after {
					display: none;
				}
			}
		}
	}
}
