.Highlight-container {
	.CTA {
		display: table;
		margin-left: auto;
		margin-right: auto;
	}
}

.Highlight-heading {
	@include component-spacing;

	color: $black;
	text-align: center;

	&:lang(ja) {
		font-size: 1rem;
	}

	.Heading-title {
		@include headingBorder-2();
	}
	.Heading-text {
		@include viewport(large) {
			@include typography(b1);
		}
		margin: 0;

		&:lang(ja) {
			font-size: 1rem;
		}
	}
}
.Highlight-pullquote {
	margin-top: 0;
	&.SpacedComponent:not(:last-child) {
		margin-bottom: 30px;
	}
}
