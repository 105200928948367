.RoomDetailsList {
	&-title {
		text-align: center;

		.Heading-title {
			margin: 0;
		}
	}

	&-list {
		display: flex;
		flex-wrap: wrap;
		margin-top: 40px;
		margin-bottom: 40px;

		// Safari bug with flexbox and display:table; from .row
		&::before,
		&::after {
			display: none;
		}

		@include viewport(medium) {
			margin-bottom: 60px;
		}

		@include viewport(xlarge) {
			margin-top: 60px;
			margin-bottom: 80px;
		}
	}

	&-item {
		margin-top: 0;
		padding-bottom: 20px;

		&:not(:first-child) {
			margin-top: 20px;
		}

		@include viewport(medium) {
			margin-top: 20px;
		}

		&-container {
			border-top: 1px solid $black;
			padding-top: 30px;
			display: flex;
			flex-direction: row;

			.RoomDetailsList--dark & {
				border-top: 1px solid $white;
			}

			&::before {
				content: '';
				margin-right: 20px;
				font-size: 20px;
				display: block;
				max-width: 20px;
			}

			@include rtl {
				&::before {
					margin-right: 0;
					margin-left: 20px;
				}
			}
		}

		&-gallery {
			display: block;
			margin-top: 20px;
		}

		&-icon {
			width: 20px;
			height: 20px;
			margin-right: 20px;
		}

		&-title {
			margin: 5px 0 20px;
		}

		&-copy {
			margin: 0;
			text-transform: none;

			&-link {
				margin-top: 20px;
			}
		}

		&-link {
			@include link('underlined', 'light');

			.RoomDetailsList--dark & {
				@include link('underlined', 'dark');
			}
		}

		&-accessibility::before {
			@include icon('icon-accessibility');
		}

		&-bathrooms::before {
			@include icon('icon-bathroom');
		}

		&-beds::before {
			@include icon('icon-beds');
		}

		&-decor::before {
			@include icon('icon-decor');
		}

		&-notes::before {
			@include icon('icon-notes');
		}

		&-occupancy::before {
			@include icon('icon-occupancy');
		}

		&-size::before {
			@include icon('icon-floorplan');
		}

		&-unique::before {
			@include icon('icon-eye_outline');
			font-size: 13px;
		}

		&-views::before {
			@include icon('icon-views');
		}

		&-sublist {
			list-style-type: disc;
			text-transform: none;
		}
	}

	&-note {
		@include serifItalic;
		font-style: italic;
	}

	&-note,
	&-cta {
		text-align: center;
		margin-bottom: 40px;
	}
}
