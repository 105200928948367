.LmlmBody.SubscriptionBody .SubscriptionContainer {
	margin-top: 20px;
}

.LmlmBody {
	.LegalAgreement {
		.Checkbox {
			margin-top: 15px;
		}
	}
	.SubscriptionChoices {
		.Intro {
			margin-top: 15px;
			margin-bottom: 15px;
		}
	}
}
