.PageFooter {
	&-logo {
		@include wls {
			text-align: center;
			& img {
				width: 150px;
			}
		}
	}

	&-address {
		margin-top: 30px;
		margin-bottom: 5px;
	}

	&-linkItem {
		display: inline-block;
		&:not(:last-child) {
			border-right: solid 1px white;
			padding-right: 10px;
			margin-right: 10px;
		}

		a {
			display: block;
		}
	}
}
