.Introduction {
	text-align: center;

	&-title {
		@include component-spacing($small: 40px, $medium: 60px, $large: 80px, $xlarge: 120px);
	}
	&-text {
		@include viewport(large) {
			@include typography(b1);
		}
	}
}
