.LwcPropertyStatuses {
	&-heading {
		.Heading-title {
			text-align: center;
			margin-top: 0;
			margin-bottom: 30px;
			padding: 0 15px;

			@include viewport(medium) {
				margin-bottom: 50px;
			}
		}
	}

	.FilteredColumnsList {
		.IconTable {
			.container {
				padding: 0;
			}
		}

		&-itemsContainer {
			padding-top: 30px;
		}

		&-item {
			margin-bottom: 30px;

			& > .IconTable-Cell .IconTable-Cell-inner {
				padding: 0;
			}
		}

		&-tabBar-GrayLine {
			@include viewport(xlarge) {
				width: calc(100% - 30px);
				margin-left: 15px;

				@include rtl {
					margin-left: 0;
					margin-right: 15px;
				}
			}
		}
	}

	.LwcStatusText p {
		margin: 0;
	}
}
