.ContactUsRegion {
	.StandardHero {
		&-title {
			padding-bottom: 30px;
		}
	}

	.ContactTable {
		margin-bottom: 60px;

		@include viewport(medium) {
			margin-bottom: 80px;
		}

		@include viewport(xlarge) {
			margin-bottom: 140px;
		}
	}
}
