.RfpHero {
	.StandardHero-heading {
		.Heading-title {
			margin-bottom: 20px;
		}

		.Heading-subtitle {
			opacity: 0.5;
		}
	}
}
