.Hero + div[id^='caw-'] {
	margin-top: -60px;
	max-width: 790px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	z-index: 2;
	@media only screen and (max-width: 1266px) {
		display: none;
	}
}

.Hero {
	&-contactBar {
		position: relative;
		margin-top: -60px;
		z-index: 2;

		@include viewport(medium) {
			margin-top: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		.ContactBar {
			&-container {
				padding-top: 20px;
				padding-bottom: 20px;
				box-shadow: 0 1px 30px 4px rgba(0, 0, 0, 0.12);
			}

			&-title {
				margin-bottom: 10px;

				@include viewport(medium) {
					margin-bottom: 0px;
				}
			}

			&-phone {
				margin-bottom: 15px;

				@include viewport(medium) {
					margin-bottom: 0px;
				}
			}
		}
	}

	&-heading-noTitle {
		display: none;
	}

	& .PropertyHero .Heading-title {
		@include wls {
			font-size: 44px;
			line-height: 62px;
			letter-spacing: 5px;
		}
	}

	& .PropertyHero .Heading-subtitle {
		@include wls {
			font-family: 'Work Sans';
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 2px;
		}
	}
}

@media only screen and (min-width: 961px) {
	.Hero-headingContainer {
		bottom: 80px;
	}
}

@media only screen and (min-width: 1701px) {
	.Hero-headingContainer {
		bottom: 100px;
	}
}
