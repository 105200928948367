.OfferDetailsList {
	&-inclusions + .OfferDetailsList-conditions > .BulletedList .BulletedList-separator:first-child {
		display: none;
	}

	.ComponentSpacing {
		.BulletedList:last-child {
			@include component-spacing;
		}
	}

	&-conditions .BulletedList:last-child {
		@include component-spacing;
	}

	.BulletedList-list {
		margin-top: 20px;

		@include viewport(medium) {
			columns: auto 2;
			column-gap: $gutter_medium;
		}
	}

	.BulletedList-body {
		@include viewport(large) {
			padding-top: 30px;
			padding-bottom: 50px;
		}

		@include viewport(xlarge) {
			padding-top: 40px;
			padding-bottom: 60px;
		}
	}

	.ItalicText-text {
		font-size: 15px;
	}
}
