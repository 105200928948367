.PPCCarousel {
	&-imagesWrapper {
		position: relative;
	}
	&-image {
		display: block;
		&::after {
			padding-bottom: 56.25% !important; //16x9
		}
	}
	&-list {
		position: relative;
		z-index: 1;
		.CarouselSlide {
			width: 100%;
			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 10;
				background-image: linear-gradient(
					rgba(0, 0, 0, 0) 0%,
					rgba(0, 0, 0, 0) 66%,
					rgba(0, 0, 0, 1) 100%
				);
			}
		}
	}
	&-Pagination {
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		bottom: 0;
		right: 14px;
		z-index: 2;
	}
	&-desc {
		margin: 0 30px;
	}
}
