.ProductCard-react-ssr-placeholder {
	background-color: white;
}

.TallImageCard.DynamicProduct {
	display: none;
}

.HideSelectStatic {
	.TallImageCard {
		&.DynamicProduct {
			display: block;
		}

		&.StaticProduct {
			display: none;
		}
	}
}
