.ActivityTabs {
	&-category {
		.BodySection .TabContainer .TabPage-components & {
			.Introduction.container.SpacedComponent {
				margin: 45px 0 0 0;
				.Introduction-text {
					margin: 0 0 45px 0;
				}
			}
		}

		.Heading {
			text-align: center;
			border-bottom: 1px solid #d3d3d3;
			margin-bottom: 25px;
			padding-bottom: 15px;
			@include viewport(large) {
				padding-bottom: 30px;
				margin-bottom: 30px;
			}

			@include viewport(xlarge) {
				padding-bottom: 40px;
				margin-bottom: 30px;
			}
			.Heading-title {
				margin: 0;
			}
		}
	}

	&-subcategory {
		padding-bottom: 10px;
	}
	&-activity {
		min-height: 261px;
		@include viewport(xlarge) {
			min-height: 271px;
		}
		padding-bottom: 20px;
		@include viewport(large) {
			padding-bottom: 30px;
		}
		&-image {
			display: block;
			font-size: 0;
		}
		.CTA {
			display: inline-block;
			width: calc(100% - 25px);
			padding-top: 20px;
			padding-bottom: 10px;
			position: relative;
			// remove the existing arrow because it doesn't work well with wrapping text
			&.CTA--arrow--light::after {
				display: none;
			}
			// arrow that works better with wrapping text
			&.CTA--arrow--light::before {
				@include icon('icon-arrow_right_large');
				font-size: 0.7rem;
				position: absolute;
				right: -25px;
			}
		}
	}
}
