.RoomType {
	&-items {
		&:not(:last-child) {
			@include component-spacing($small: 80px, $medium: 120px, $large: 70px, $xlarge: 180px);
		}
		.RoomItem {
			&:not(:last-child) {
				@include component-spacing($small: 80px, $medium: 120px, $large: 70px, $xlarge: 180px);
			}
			// Make the image and .RoomItem-content have the same height
			// when there's text that doesn't go beyond the image height
			&-row {
				@include viewport(large) {
					display: flex;
					width: 100%;
					flex-direction: row;
				}
				&-col {
					@include viewport(large) {
						display: flex;
					}
				}
			}
			&-wrapper {
				position: relative;
			}
			&-content {
				@include viewport(large) {
					margin-left: 18px;
					display: flex;
					width: 100%;
					flex-direction: column;
					justify-content: space-between;
				}
				@include viewport(xlarge) {
					margin-left: 86px;
				}
				@include rtl {
					@include viewport(large) {
						margin-left: 0;
						margin-right: 18px;
					}
					@include viewport(xlarge) {
						margin-right: 86px;
					}
				}
			}
			&-picture {
				position: relative;
				display: block;
				font-size: 0;
				margin-bottom: 32px;

				@include viewport(large) {
					margin-bottom: 0;
				}

				.Image-picture {
					position: relative;
					width: auto;
					height: auto;
					margin: auto;
					right: 0;
					overflow: hidden;

					img {
						display: block;
					}
				}
			}
			&-rentalLabel {
				display: block;
				position: absolute;
				word-wrap: break-word;
				width: 124px;
				background-color: $black;
				color: $white;
				padding: 6px;
				padding-left: 30px;
				top: 10px;
				left: 7px;

				@include viewport(medium) {
					left: 15px;
				}

				@include rtl {
					right: 7px;
					padding-left: 0;
					padding-right: 30px;

					@include viewport(medium) {
						right: 15px;
					}
				}
			}
			&-name {
				display: flex;
			}
			&-features {
				margin-top: 30px;
				margin-bottom: 30px;

				@include viewport(medium) {
					margin-bottom: 40px;
				}
			}
			&-feature {
				list-style: none;
				display: flex;
				align-items: center;

				&-description {
					text-transform: none;
				}

				&:not(:last-child) {
					margin-bottom: 13px;
					@include viewport(medium) {
						margin-bottom: 18px;
					}
				}
				&::before {
					content: '';
					padding-right: 14px;
					align-self: flex-start;
					font-size: 15px;
					line-height: em(16, 12);
				}
				@include viewport(medium) {
					&::before {
						padding-right: 20px;
					}
				}
				@include rtl {
					&::before {
						padding-right: 0;
						padding-left: 14px;
					}
					@include viewport(medium) {
						&::before {
							padding-left: 20px;
						}
					}
				}
				&.icon {
					&-beds::before {
						@include icon('icon-beds');
					}
					&-size::before {
						@include icon('icon-floorplan');
					}
					&-occupancy::before {
						@include icon('icon-occupancy');
					}
					&-accessibility::before {
						@include icon('icon-accessibility');
					}
					&-bathroom::before {
						@include icon('icon-bathroom');
					}
					&-view::before {
						@include icon('icon-views');
					}
					&-decor::before {
						@include icon('icon-decor');
					}
					&-uniqueFeatures::before {
						@include icon('icon-eye_outline');
					}
					&-accessibleFeatures::before {
						@include icon('icon-accessibility');
					}
					&-generalNotes::before {
						@include icon('icon-notes');
					}
				}
			}
			&-featureIcon {
				padding-right: 14px;
				align-self: flex-start;
				font-size: rem(15, 16);
				line-height: em(16, 12);
				flex-basis: 29.52px;
				max-width: 29.52px;

				@include viewport(medium) {
					padding-right: 20px;
					flex-basis: 35.52px;
					max-width: 35.52px;
				}

				@include rtl {
					padding-right: 0;
					padding-left: 14px;

					@include viewport(medium) {
						padding-left: 20px;
					}
				}
			}
			&-additionalFeature {
				list-style: none;
				display: flex;
				align-items: center;

				&:not(:last-child) {
					margin-bottom: 13px;

					@include viewport(medium) {
						margin-bottom: 18px;
					}
				}

				.icon-fitness_facilities {
					padding-right: 10px;
					flex-basis: auto;

					&::before {
						font-size: 10px;
					}

					@include viewport(medium) {
						padding-right: 14px;

						&::before {
							font-size: 11px;
						}
					}
				}
			}
			&-ctas {
				display: flex;
				flex-direction: row;
			}

			&-cta {
				&:last-child {
					min-width: auto;
					@include viewport(large) {
						min-width: 130px;
					}
				}
				&:not(:last-child) {
					margin-right: 20px;
					@include viewport(xlarge) {
						margin-right: 30px;
					}
					@include rtl {
						margin-right: 0;
						margin-left: 20px;
						@include viewport(xlarge) {
							margin-left: 30px;
						}
					}
				}
			}
			&-callToBook {
				display: flex;
				flex-direction: column;
				&-copy {
					margin-bottom: 5px;
				}
				&-number {
					@include grayHover('light');
					color: $black;
					text-decoration: none;
				}
			}
		}
	}
	&-experience {
		margin: 80px 0 auto;
		text-align: center;
		@include viewport(medium) {
			margin: 120px 0 auto;
		}
		@include viewport(large) {
			margin: 140px 0 auto;
		}
		.Heading {
			margin-bottom: 26px;
			@include viewport(medium) {
				margin-bottom: 36px;
			}
			.Heading-title {
				padding-bottom: 25px;
				margin-bottom: 0;
				text-align: center;
				position: relative;
				&::after {
					content: '';
					position: absolute;
					display: block;
					bottom: 13px;
					width: 40px;
					left: calc(50% - 20px); // Half the width of the underline
					border-top: 2px solid;
				}
			}
			.Heading-text {
				margin: 0;

				&:lang(ja) {
					font-size: 1rem;
				}
			}
		}
	}

	&-wrapper {
		padding: 0;

		@include viewport(medium) {
			margin-bottom: 60px;
		}
		@include viewport(large) {
			margin-bottom: 70px;
			margin-top: -30px;
		}
	}

	&-introParagraph {
		display: flex;
		flex-direction: column;

		&::after {
			content: '';
			display: block;
			border-bottom: 1px solid $separatorLight;
			margin-top: 40px;
			margin-bottom: 45px;
		}

		@include viewport(medium) {
			flex-direction: row;

			&::after {
				content: none;
			}
		}
	}

	&-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 40px;

		&::before {
			display: block;
			position: relative;
			text-align: center;
			font-size: 68px;
			margin-top: 22px;
		}

		@include viewport(medium) {
			display: block;
			position: relative;
			padding-left: 11px;
			padding-right: 41px;
			margin-bottom: 0;
			min-width: 128px;

			&::before {
				position: absolute;
				margin-top: 0;
				bottom: 50%;
				transform: translateY(50%);
			}

			@include rtl {
				padding-left: 41px;
				padding-right: 11px;
			}
		}

		@include viewport(large) {
			padding-left: 32px;
			padding-right: 60px;
			min-width: 165px;

			@include rtl {
				padding-left: 60px;
				padding-right: 32px;
			}
		}
	}

	&-description {
		display: block;
		text-align: center;

		@include viewport(medium) {
			text-align: left;

			@include rtl {
				text-align: right;
			}
		}
	}
}
