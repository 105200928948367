.membershipTierDetail {
	.feesAndConditions {
		.annualFee-title {
			margin-bottom: 18px;
			margin-top: 18px;
		}
		&-container {
			margin-bottom: 18px;
			margin-top: 18px;
		}
	}
	.discount {
		.discount-title {
			margin-bottom: 18px;
			margin-top: 18px;
		}
	}
	.voucher {
		.voucher-title {
			margin-bottom: 18px;
			margin-top: 18px;
		}
	}
	.discountList .voucherList {
		padding-bottom: 5.5px;
		margin-bottom: 2px;
	}
	.termsConditions {
		margin-left: 10px;
	}
	.feesAndConditions-container {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.discountList,
	.voucherList {
		list-style-type: disc !important;
		list-style-position: inside;
		padding-left: 40px;
		text-indent: -1em;
	}
}
