.DescriptionAndBooking {
	&-intro {
		margin-bottom: 0;

		&.SpacedComponent:not(:last-child) {
			margin-bottom: 0;
		}

		.Introduction-text {
			margin: 0;
		}
	}

	&-cta {
		display: block;
		text-align: center;
		margin-top: 30px;

		@include viewport(medium) {
			margin-top: 40px;
		}

		@include viewport(xlarge) {
			margin-top: 60px;
		}
	}

	&-contact {
		margin-top: 60px;
		margin-bottom: 0;

		&.SpacedComponent:not(:last-child) {
			margin-bottom: 0;
		}

		@include viewport(medium) {
			margin-top: 80px;
		}

		@include viewport(large) {
			margin-top: 100px;
		}

		@include viewport(xlarge) {
			margin-top: 140px;
		}

		.ContactBar-phone {
			margin-bottom: 0;
		}
	}
}
