.AccommodationsListing-card {
	min-height: 300px;
	border: 1px solid $gray3;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: $white;
	color: $black;

	&--dark {
		border: 1px solid $gray1;
	}

	&-imageCarouselContainer {
		position: relative;
	}

	&-imageCarousel {
		overflow: hidden;
		&-slide {
			width: 100%;
		}

		&-navigation {
			position: absolute;
			right: 0;
			bottom: 0;
			background-color: change-color($color: $black, $alpha: 0.92);
			color: $white;
			border: 1px solid change-color($color: $black, $alpha: 0.3);
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;

			@include viewport(large) {
				height: 45px;
			}

			@include rtl {
				right: auto;
				left: 0;
			}
		}

		&-pagination {
			display: flex;

			&--button {
				font-size: 10px;
				width: 30px;

				@include viewport(large) {
					font-size: 15px;
					width: 40px;
					height: 45px;
				}
			}
		}
	}

	&-image {
		padding-top: 56.25%;
	}

	&-body {
		padding: 29px;
		height: 100%;
		display: flex;
		flex-direction: column;

		&-iconsContainer {
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;
		}

		&-iconText {
			display: flex;
			align-items: center;
			&-icon {
				vertical-align: middle;
				font-size: rem(16, 16);
				margin-right: 10px;
				max-width: 16.51px;
				padding-top: 2px;

				@include rtl {
					margin-right: 0;
					margin-left: 15px;
				}
			}
			&-text {
				text-transform: none;
				letter-spacing: 1.3px;
			}
		}

		&-ctaContainer {
			margin-top: auto;
			display: flex;
		}

		&-cta {
			width: calc(50% - 5px);
			padding: 12px 14px;

			@include viewport(medium) {
				width: 135px;
			}

			@include viewport(large) {
				width: calc(50% - 5px);
			}

			& + & {
				margin-left: 10px;

				@include viewport(medium) {
					width: 125px;
					min-width: 125px;
				}

				@include viewport(large) {
					width: calc(50% - 5px);
				}

				@include rtl {
					margin-left: 0;
					margin-right: 10px;
				}
			}
		}

		&-callToBook {
			display: flex;
			flex-direction: column;
			text-align: center;
			justify-content: center;
			width: calc(50% - 5px);
			margin-left: 10px;

			@include rtl {
				margin-left: 0;
				margin-right: 10px;
			}
			&-text {
				margin-bottom: 3px;
			}
		}
	}

	&-title {
		margin-top: 0;
		margin-bottom: 20px;
	}
}

@include viewport(large) {
	.FilteredColumnsList-item {
		&--list {
			.AccommodationsListing-card {
				flex-direction: row;
				&-imageCarouselContainer {
					width: 57%;
					min-height: 300px;
				}

				&-imageCarousel {
					height: 100%;

					&-slide {
						height: 100%;
						width: 100%;
						overflow: hidden;
					}

					&-navigation {
						bottom: 34px;
						height: 40px;
					}

					&-pagination {
						&--button {
							font-size: 12px;
							width: 33px;
							height: 48px;
						}
					}
				}

				&-image {
					height: 100%;
					padding-top: 0;
					.Image-img {
						width: auto;
						float: right;
					}
				}

				&-title {
					margin-bottom: 30px;
				}

				&-body {
					width: 43%;
					padding: 34px 29px 34px 40px;

					&-iconsContainer {
						margin-bottom: 30px;
					}

					&-cta {
						width: 135px;
					}
				}
			}
		}
	}
}
