.GroupOfferCard {
	&-list {
		.BulletedList-body {
			padding: 20px 0;
			padding-left: 20px;

			.BulletedList-list {
				margin: 0;
				columns: unset;
			}
		}
	}

	.OfferCard-details {
		border-top: 1px solid $gray3;
	}

	.OfferCard-cta-details {
		margin-left: auto;
	}

	&-secondary-offer {
		color: $gray2;
	}

	&-rate {
		padding-top: 20px;
		border-top: 1px solid $gray3;
	}

	&-dates {
		padding-top: 20px;
		padding-bottom: 25px;
		border-top: 1px solid $gray3;
	}

	&-inclusion {
		padding-top: 20px;
		border-top: 1px solid $gray3;
	}

	&-talk {
		display: flex;
		width: 100%;
		padding: 25px 0;
		border-top: 1px solid $gray3;

		span {
			width: 50%;
		}

		.GroupOfferCard-phone {
			width: 50%;
			text-align: right;
		}
	}

	.BulletedList-separator {
		display: none;
	}

	&-terms {
		padding-top: 25px;
		border-top: 1px solid $gray3;
		font-size: 1rem;
	}
}
