.ActivityDetail {
	&-cta {
		margin-top: 50px;
	}
	&-open-chat {
		display: none;
	}
	&-image {
		display: block;
		font-size: 0;
	}
	.Introduction-text {
		margin-top: 35px;
		margin-bottom: 0px;
		@include viewport(large) {
			margin-top: 60px;
		}
	}
}
