.OffersList {
	&--dark {
		.OfferCard-container {
			border-color: $black;
		}
	}

	&--light {
		.OfferCard-container {
			border-color: $white;
		}
	}

	&-heading {
		@include component-spacing;
		text-align: center;
		.Heading-title {
			margin: 0;
		}
	}
	&-offer {
		&:last-child {
			.OfferCard-container {
				border-bottom: 1px solid;
			}
		}
		.OfferCard-container {
			box-shadow: none;
			border-top: 1px solid;
		}
	}
}
