.HideSelectStatic {
	.ProductDetailPage .BodySection > div.StaticProductDetails {
		display: none;
	}
}

.ProductDetailPage {
	.BodySection {
		padding-top: 0;
	}

	.AddToCard-Header {
		display: flex;
		flex-direction: column;
		gap: 20px;

		.StandardHero-breadcrumb {
			margin: 0;
			padding: 0;
			line-height: 1em;
		}

		.ty-h2,
		.ty-h4 {
			line-height: 1em;
		}
	}

	.OverlayPage-Header {
		display: none;
	}

	.open-gallery {
		position: relative;
	}

	.Gallery-Signature-mobile-button {
		display: flex; // Consolidated to flex only, as it overrides block
		padding: 5px 10px;
		width: max-content;
		height: 35px;
		left: 15px;
		margin-top: -30px;
		color: #fff;
		border-radius: 4px;
		border: 1px solid var(--color-border-subtle-inverse, rgba(77, 77, 77, 0.15));
		background: rgba(3, 3, 3, 0.6);
		gap: 10px;
		align-items: center;
		backdrop-filter: blur(15px); // default/blur/heavy-30
		z-index: 8;
		position: absolute;
		bottom: 15px;

		&::before {
			content: '\f1cb';
			font-family: 'fs-iconfont';
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-style: normal;
			font-variant: normal;
			font-weight: normal;
			text-decoration: none;
			text-transform: none;
			line-height: 0.7em;
		}
		@include viewport('medium') {
			position: absolute;
			right: 20px;
			top: 50px;
			left: unset;
		}
		@include viewport('large') {
			display: none;
		}
		@include viewport(large) {
			display: flex; // Consolidated to flex only, as it overrides block
		}
	}

	.image-addtocart-signature {
		.fullscreen-image {
			display: none;
			position: relative;
			width: 100%;
			aspect-ratio: 16 / 9;
			height: auto;
			cursor: pointer;
		}
		.addtocart-product-signature {
			.addtocart-eyebrow-signature {
				display: none;
			}
		}
		.signature-squareImage {
			&-container {
				display: none;
			}
		}
		.Gallery-Signature-button {
			@extend .Gallery-Signature-mobile-button;
			display: none;
			@include viewport('large') {
				display: flex;
				position: absolute;
				z-index: 8;
				display: block;
			}
		}
	}

	.image-addtocart-grid {
		display: grid;
		grid-template-columns: minmax(0, 1fr);
		grid-column-gap: 30px;
		grid-row-gap: 30px;

		.grid-img-container {
			min-width: 0;
			min-height: 0;

			img {
				width: 100%;
			}

			.landscape {
				display: block;
			}

			.portrait {
				display: none;
			}

			.Gallery-button {
				display: block;
				position: relative;
				border: 1px solid #000;
				background-color: #000;
				opacity: 60%;
				padding: 5px 10px;
				left: 15px;
				bottom: 15px;
				margin-top: -30px;

				.Button-label {
					font-weight: 200;
					letter-spacing: -1px;
					line-height: 1em;
				}

				&::before {
					content: '\f1cb';
					font-family: 'fs-iconfont';
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					font-style: normal;
					font-variant: normal;
					font-weight: normal;
					text-decoration: none;
					text-transform: none;
					line-height: 0.7em;
				}
			}
		}

		&.inline-carousel {
			margin-top: -40px;

			.grid-img-container {
				display: none;
			}
		}
	}

	.addtocart-product-details {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	#overview {
		scroll-margin-top: 60px;
	}

	.Text-Box {
		text-align: left;

		.Text-Box-title {
			margin: 0 0 30px;
		}

		div {
			margin: 0;
			padding: 0;
			width: 100%;
		}
	}

	.ImageAndText {
		&-primaryHeading .Heading-title {
			text-align: left;

			@include rtl {
				text-align: right;
			}
		}
	}

	.ItineraryAccordion {
		.ComponentAccordion-title {
			text-align: left;
		}

		.Itinerary-description {
			padding-bottom: 20px;
		}

		.RichText {
			.container {
				padding: 0;
			}
		}
	}

	@media only screen and (min-width: 961px) {
		.inline-carousel-container {
			display: none;
		}

		.image-addtocart-signature {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			.signature-squareImage {
				&-container {
					display: flex;
					flex-direction: row;
					width: 100%;
					gap: 2px;

					.Video-playButton {
						position: absolute;
						bottom: 6px;
						right: 6px;
						&::before {
							background-color: rgba(3, 3, 3, 0.4);
							border-radius: 50%;
						}
					}
				}
				&-button {
					cursor: pointer;
					flex-basis: calc(50% - 1px);
				}
				&-video {
					position: absolute;
					top: 0;
					overflow: hidden;
					aspect-ratio: 1;
					height: auto;
					width: 50%;

					.Video-video {
						position: absolute;
						width: auto;
						height: 100%;
						top: 50%;
						left: 50%;
						min-height: 100%;
						min-width: 100%;
						-webkit-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
					}
				}
			}
			.fullscreen-image {
				display: block;
			}
			.addtocart-product-signature {
				display: flex;
				flex-direction: column;
				gap: 20px;
				width: 100%;
				z-index: 1;
				margin-top: -175px;
				min-height: 175px;
				padding: 30px 50px;
				width: calc(100% - 114px);

				.AddToCard-Header {
					text-align: center;
					gap: 5px;
					.ty-h4 {
						line-height: 27px;
					}
					.ty-h2 {
						line-height: 31px;
					}
					.addtocart-eyebrow-signature {
						display: inline;
						& + .CTA {
							display: none;
						}
					}
				}
			}

			.image-addtocart-grid {
				display: block;
				margin-top: 0;

				.grid-img-container {
					display: none;
				}
			}
		}

		.image-addtocart-grid {
			grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
			margin-top: 50px;

			.grid-img-container {
				display: block;

				.images {
					position: sticky;
					top: 70px;
					right: 0;
				}

				.landscape {
					display: none;
				}

				.portrait {
					display: block;
				}
			}

			&.inline-carousel {
				margin-top: 50px;

				.grid-img-container {
					display: block;
				}
			}
		}

		#overview {
			scroll-margin-top: 70px;
		}
	}

	@media only screen and (max-width: 960px) {
		.addtocart-product-signature.surface-raised {
			box-shadow: unset;
			position: relative;
		}
	}
}
