.VenueFinder {
	&-mobile {
		width: 100%;

		@include viewport(large) {
			display: none;
		}
	}
	&-mobile-table-section {
		padding-top: 20px;

		&:not(:first-child) {
			text-align: right;

			@include rtl {
				text-align: left;
			}
		}
	}

	&-header {
		@include viewport(medium) {
			display: flex;
		}

		width: 100%;

		&-title {
			flex: 1;
		}

		&-unitSelectWrapper {
			select {
				background: none;
				border: none;
				line-height: 39px;
				height: 39px;
				width: 100%;
				padding-right: 15px;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;

				@include viewport(medium) {
					margin-top: 10px;
				}

				@include rtl {
					padding-right: 0px;
					padding-left: 15px;
				}

				option {
					font-size: 0.75rem;
				}
			}

			&::after {
				right: 0;
			}

			@include rtl {
				&::after {
					left: 0;
				}
			}

			@include viewport(medium) {
				&::after {
					top: 15px;
				}
			}
		}
	}

	&-icon {
		vertical-align: middle;

		&::before {
			padding-right: 5px;

			@include rtl {
				padding-right: 0;
				padding-left: 5px;
			}
			color: $bhover;
		}

		&-cta {
			text-decoration: none;
			color: #000;
			&:hover {
				color: $bhover;
			}
		}
	}

	&-wrap {
		overflow: hidden;
		padding-bottom: 20px;
		transition: height 1s;
	}

	&-wrap.expanded + &-seeAll .shrink {
		display: block;
	}

	&-wrap.expanded + &-seeAll .expand {
		display: none;
	}

	.heightAuto {
		height: auto;
	}

	&-seeAll {
		display: none;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		.shrink {
			display: none;
		}
	}

	&-table {
		position: relative;
		margin: 10px auto;
		padding: 0;
		width: 100%;
		height: auto;
		border-collapse: collapse;
		text-align: center;

		&-header {
			display: none;

			@include viewport(large) {
				display: table-header-group;
			}

			&-item.ty-q1 {
				font-size: 1.125rem;
			}
		}

		&-section {
			&-row {
				&-header {
					background: $gray4;
					@include typography('h3');
					font-size: 0.75rem;
					max-width: 100%;
				}
			}

			&-header {
				text-align: left;
				padding: 25px 0 25px 25px;

				@include rtl {
					text-align: right;
					padding-right: 25px;
					padding-left: 0;
				}
			}

			&-item {
				&:first-child {
					text-align: left;

					@include rtl {
						text-align: right;
					}
				}

				padding: 15px 15px;

				@include viewport(medium) {
					padding: 15px 60px;
				}

				@include viewport(large) {
					padding: 15px 0 15px 15px;

					@include rtl {
						padding-right: 15px;
						padding-left: 0;
					}
				}

				.CTA {
					text-transform: none;
				}
			}

			&-item.Size,
			&-item.Seating {
				display: none;

				@include viewport(large) {
					display: table-cell;
				}
			}
		}
	}
}
